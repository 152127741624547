const url = require("url");
const path = require("path");

const extractFilename = (link) => {
  const parsed = url.parse(link);
  const imageFilename = path.basename(parsed.pathname);
  const imageFilenameWithoutExtension = imageFilename
    .split(".")
    .slice(0, -1)[0];
  return { imageFilename, imageFilenameWithoutExtension };
};

module.exports = extractFilename;
