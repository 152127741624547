import Link from "next/link";
import priceHelper from "../../../helpers/priceHelper";

export default function CoursePricing({ course }) {
  const priceData = priceHelper(course);
  const cleanUrl = course.cleanUrl.replace(/\//g, "");

  return (
    <Link
      href={`/udemy/${cleanUrl.replace(/\//g, "")}`}
      as={`/udemy/${cleanUrl.replace(/\//g, "")}`}
      passHref
      prefetch={false}
    >
      <div
        className={`flex h-[55px] flex-col justify-center border-t-2 border-primary/20 tracking-widest hover:underline text-${priceData.colorTailwind}-500 bg-card text-center font-bold`}
      >
        {priceData.text}
      </div>
    </Link>
  );
}
