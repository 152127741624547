export default function priceHelper(course) {
  let result = {};
  // Free
  if ((course.isPaid && course.isFree) || !course.isPaid) {
    result = {
      color: "success",
      colorHex: "limegreen",
      colorTailwind: "green",
      text: "FREE",
      textEnroll: "FREE",
      price: "$ 0",
      ago: course.updatedAt,
    };
  }

  // Paid
  if (course.isPaid && !course.isFree) {
    result = {
      color: "paid",
      colorHex: "#03a9f4",
      colorTailwind: "blue",
      text: `$${course.price}`,
      textEnroll: `REGULAR $${course.price / 100}`,
      price: `$${course.price}`,
      ago: course.createdAt,
    };
  }

  // Coupon
  if (course.code) {
    // Expired
    if (!course.codeIsValid) {
      result = {
        color: "paid",
        colorHex: "#03a9f4",
        colorTailwind: "blue",
        text: `$${course.price}`,
        textEnroll: `REGULAR $${course.price / 100}`,
        price: `$${course.price}`,
        ago: course.couponCreatedAt,
        used: course.maxUses
          ? course.maxUses - course.remainingUses
          : undefined,
      };
    } else {
      // Discounted
      if (course.discountPrice != 0) {
        result = {
          color: "error",
          text: `$${course.discountPrice}`,
          colorHex: "red",
          colorTailwind: "red",
          textEnroll: `$${course.discountPrice} COUPON`,
          price: `$${course.discountPrice}`,
          ago: course.couponCreatedAt,
          expire: course.endTime,
        };
      }
      // Full Discount
      if (course.discountPrice == 0) {
        result = {
          color: "warning",
          text: course.discountValue.toUpperCase(),
          colorHex: "orange",
          colorTailwind: "yellow",
          textEnroll: `100%OFF coupon`,
          price: `$${course.discountPrice}`,
          ago: course.couponCreatedAt,
          expire: course.endTime,
          remainingUses: course.remainingUses,
        };
      }
    }
  }

  return result;
}

// colorHex: "#03a9f4",
//         text: `$${course.price}`,
//         textEnroll: `ENROLL: Regular Price`,
