import CourseHeader from "./CourseHeader";
import CourseImage from "./CourseImage";
import CourseMetrics from "./CourseMetrics";
import CoursePricing from "./CoursePricing";

export default function CourseThumb({
  course,
  hideHeader,
  hideMetrics,
  className,
}) {
  // console.log(course);
  return (
    <div
      className={`group relative w-[307px] cursor-pointer rounded border-2 border-primary/20 bg-background shadow-2xl duration-500 ease-in-out hover:scale-105 ${className}`}
    >
      {hideHeader ? null : <CourseHeader course={course} />}
      <CourseImage course={course} />
      {hideMetrics ? null : <CourseMetrics course={course} />}
      <CoursePricing course={course} />
    </div>
  );
}
