"use client"
import AdSense from 'react-adsense'

export default function AdCard({ className }) {
  if (process.env.AD_CARD === "false") {
    return null;
  }
  return (
    <div
      className={`${className} flex h-[460px] w-[307px] cursor-pointer flex-col justify-center rounded border-2 border-th-primary-medium bg-th-background p-4 shadow-2xl duration-500 ease-in-out hover:scale-105`}
    >
      <AdSense.Google
        style={{
          display: "block",
          height: "452px",
        }}
        client="ca-pub-2173566842452806"
        slot="6148805910"
        format="fluid"
        layoutKey="+3u+p7+1h-8+dv"
      />
    </div>
  );
}
