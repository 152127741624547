import kFormater from "../../../helpers/kFormater";
import formatUpdatedAt from "../../../helpers/formatedUpdatedAt";

export default function CourseMetrics({ course }) {
  const cleanUrl = course.cleanUrl.replace(/\//g, "");
  return (
    <div className="cursor-default bg-card">
      <div className="flex justify-between text-muted-foreground">
        <div className="flex w-full flex-col py-4 text-center">
          <div className="text-sm font-bold">
            {kFormater(course.subscribers)}
          </div>
          <div className="text-xs font-normal">students</div>
        </div>
        <div className="flex w-full flex-col py-4 text-center">
          <div className="text-sm font-bold">
            {course.length.replace(/total/g, "")}
          </div>
          <div className="text-xs font-normal">content</div>
        </div>
        <div className="flex w-full flex-col py-4 text-center">
          <div className="text-sm font-bold">
            {formatUpdatedAt(course.updated)}
          </div>
          <div className="text-xs font-normal">updated</div>
        </div>
      </div>
    </div>
  );
}
