import { Badge } from "@/components/ui/badge";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { useEffect, useState } from "react";

export function CountBadge({ count }) {
  const [formattedCount, setFormattedCount] = useState("");
  const countValue = useMotionValue(0);
  
  // Effet de lueur animée
  const glowVariants = {
    initial: { boxShadow: "0 0 0 rgba(255, 255, 255, 0)" },
    animate: {
      boxShadow: [
        "0 0 0 rgba(255, 255, 255, 0)",
        "0 0 10px rgba(255, 255, 255, 0.3)",
        "0 0 20px rgba(255, 255, 255, 0.2)",
        "0 0 10px rgba(255, 255, 255, 0.1)",
        "0 0 0 rgba(255, 255, 255, 0)"
      ],
      transition: {
        duration: 2,
        times: [0, 0.25, 0.5, 0.75, 1],
        repeat: 0,
        ease: "easeInOut"
      }
    }
  };

  // Animation d'entrée du badge
  const badgeVariants = {
    initial: { 
      opacity: 0,
      scale: 0.6,
      filter: "blur(4px)"
    },
    animate: { 
      opacity: 1,
      scale: 1,
      filter: "blur(0px)",
      transition: { 
        duration: 0.6,
        ease: [0.23, 1, 0.32, 1] // Courbe cubique personnalisée pour un mouvement élégant
      }
    }
  };
  
  // Animation du compteur
  useEffect(() => {
    const controls = animate(countValue, count, {
      duration: 1.5,
      ease: "easeOut"
    });
    
    return controls.stop;
  }, [count, countValue]);
  
  // Formater le nombre à chaque changement de la valeur animée
  useEffect(() => {
    const unsubscribe = countValue.onChange(value => {
      setFormattedCount(new Intl.NumberFormat('fr-FR').format(Math.floor(value)));
    });
    
    return unsubscribe;
  }, [countValue]);
  
  return (
    <motion.div
      initial="initial"
      animate="animate"
      variants={badgeVariants}
      whileHover={{ 
        scale: 1.08,
        transition: { duration: 0.3 }
      }}
    >
      <motion.div variants={glowVariants}>
        <Badge className="ml-3 h-8 rounded-full bg-gradient-to-r from-gray-900 to-gray-800 dark:from-gray-100 dark:to-gray-200 px-3 py-2 text-center text-sm font-bold text-white dark:text-gray-800 border border-gray-700/50 dark:border-gray-300/50 shadow-lg sm:h-10 sm:px-4 sm:text-base relative overflow-hidden">
          <motion.span className="relative z-10">
            {formattedCount}
          </motion.span>
          <motion.div 
            className="absolute inset-0 bg-gradient-to-r from-primary/20 to-primary/0 dark:from-primary/10 dark:to-primary/0 opacity-30"
            animate={{
              x: ["0%", "100%"],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              repeat: 0,
              repeatType: "reverse"
            }}
          />
        </Badge>
      </motion.div>
    </motion.div>
  );
} 