import Link from "next/link";
import ReactCountryFlag from "react-country-flag";

import cleanInstructorUrl from "../../../helpers/cleanInstructorUrl";
import extractFilename from "../../../helpers/extractFilename";

export default function CourseHeader({ course }) {
  const instructor = course.instructorAvatar
    ? extractFilename(course.instructorAvatar)
    : null;
  return (
    <Link
      href={`/instructor/${cleanInstructorUrl(course.instructorUrl)}`}
      as={`/instructor/${cleanInstructorUrl(course.instructorUrl)}`}
      passHref
      prefetch={false}
    >
      <div className="flex h-[65px] cursor-pointer items-center justify-between border-b-2 border-primary/20 bg-card p-2">
        <div className="flex ml-2">
          <picture>
            {/* <source
        srcSet={`https://cdn-thumbs.comidoc.net/100/webp/${instructor.imageFilenameWithoutExtension}.webp`}
        type="image/webp"
      /> */}
            <source
              srcSet={`https://cdn-thumbs.comidoc.net/100/${instructor?.imageFilename}`}
              type="image/jpeg"
            />
            <img
              alt={`Instructor ${course.instructorName}`}
              src={`https://cdn-thumbs.comidoc.net/100/${instructor?.imageFilename}`}
              width={45}
              height={45}
              className="rounded"
              loading="lazy"
            />
          </picture>
          <div className="flex flex-col ml-4 text-card-foreground">
            <div className="text-sm font-bold capitalize hover:underline">
              {course?.instructorName?.substr(0, 18)}
            </div>
            <div className="text-sm font-normal text-muted-foreground">{`${
              course.instructorCourses?.length
            } course${course.instructorCourses?.length > 1 ? "s" : ""}`}</div>
          </div>
        </div>

        <ReactCountryFlag
          className="mr-4"
          loading="lazy"
          svg
          countryCode={course.locale.slice(3, 5)}
          style={{
            fontSize: "20px",
          }}
          aria-label={course.locale}
          alt={course.locale}
        />
      </div>
    </Link>
  );
}
